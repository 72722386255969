<template>
 <div>
    <div class="my-5 text-center gap-2 flex">
        <input type="text" name="ip" id="ip" placeholder="آدرس آیپی" v-model="IP" class="border-0 rounded bg-grey-light px-2 py-2 text-black">
        <button @click="getLogs(IP), isData = true" class="px-2 py-2 bg-secondary rounded border-0 text-white
        ">دریافت اطلاعات</button>
    </div>

    <div v-if="isData">
        <div class="flex text-white bg-primary py-4 px-4 rounded my-2 text-sm text-center" >
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> یوزر  </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> نود </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> آیدی آیپی </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> شماره ماشین</span>
            <span class="w-48 border-solid border-0 border-r border-white border-opacity-25 px-2"> تاریخ ساخت </span>
            <span class="w-48 border-solid border-0 border-r border-white border-opacity-25 px-2"> تاریخ حذف </span>
           
        </div>
        <div class="flex text-white bg-primary py-4 px-4 rounded my-2 text-sm text-center" :key="log" v-for="log in logs">
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.user_id }} </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.node_id }} </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.ip_id }} </span>
            <span class="w-24 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.vm_number }} </span>
            <span class="w-48 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.created_at }} </span>
            <span class="w-48 border-solid border-0 border-r border-white border-opacity-25 px-2"> {{ log.deleted_at }} </span>
            
        </div>
    </div>
 </div>
</template>

<script>
    export default {

        data: () => ({
            IP:'',
            logs:[],
            isData:false
        }),

        methods: {
            getLogs() {
                 this.$http.post(
                    '/admin/cloud/ip/logs', { ip_address: this.IP }
                 ).then((res) => {
                    this.logs = res.data.params;
                 })
            }
        },

        created() {

        }

    }
</script>